// accordion_controller.js

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content"];

  connect() {
    this.contentTargets.forEach((content) => {
      content.style.display = "none";
    });
  }

  toggle(event) {
    const button = event.currentTarget;
    const contentId = button.getAttribute("aria-controls");
    const content = document.getElementById(contentId);

    const isExpanded = button.getAttribute("aria-expanded") === "true";
    button.setAttribute("aria-expanded", !isExpanded);
    content.style.display = isExpanded ? "none" : "block";
  }
}
